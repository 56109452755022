<template>
  <div id="app">
    <Main v-if="logged"></Main>
    <router-view v-else />
  </div>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from "vuex";
import ls from "local-storage";
import Main from "@/views/layout/Main"

export default {
  name: "App",
  components: {
    Main
  },
  computed: {
    ...mapGetters("account", ["logged"]),
    ...mapGetters(["path", "full_path"]),
    isRouteMain() {
      let routes = ['profile', 'dashboard'];
      console.log(routes.includes(this.path))
      return routes.includes(this.path);
    }
  },
  data() {
    return {
      showText: true,
    };
  },
  mounted() {
    this.checkLoged();
    let checkLive = process.env.VUE_APP_API_URL;
    if (checkLive == 'https://api-client.hangve.com/api/') {
      if (location.protocol !== "https:") {
        location.protocol = "https:";
      }
    }
  },
  methods: {
    ...mapActions("account", ["getAccountInfo"]),
    checkLoged() {
      let authen = ls.get("authen");
      if (!authen) {
        let routesNotRedirect = ['register', 'register-success', 'forgot-password', '', 'reset-password', 'reset-success'];
        if (!routesNotRedirect.includes(this.path)) {
          this.$router.push({ name: "account-login" });
        }
      } else {
        if (!this.logged) {
          this.getAccountInfo();
        }
      }
    },

    toggleMenuParent(value) {
      this.showText = value;
    },
  },
};
</script>

<style type="text/css">
  @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

  body {
    font-family: 'Roboto', sans-serif !important;
  }
</style>

<template>
  <div class="rp-home">
    <div class="box-rp">
      <div class="label-box">
        <span>Đơn hàng</span>
        <img src="@/static/icon/report/order.svg" width="36px" height="36px" />
      </div>
      <div class="report-status" style="padding-top: 10px">
        <span class="label">Hoàn thành</span>
        <span class="num bg1" v-if="orderClientPanel.status_4">{{ orderClientPanel.status_4.total_order | vnd2 }}</span>
      </div>
      <div class="report-status">
        <span class="label">Đã đặt hàng</span>
        <span class="num bg2" v-if="orderClientPanel.status_3">{{ orderClientPanel.status_3.total_order | vnd2 }}</span>
      </div>
    </div>
    <div class="box-rp">
      <div class="label-box">
        <span>Kiện hàng</span>
        <img src="@/static/icon/report/pack.svg" width="36px" height="36px" />
      </div>
      <div class="report-status" style="padding-top: 10px">
        <span class="label">Đã nhận hàng</span>
        <span class="num bg1" v-if="orderPackageClientPanel.status_16">{{ orderPackageClientPanel.status_16.total_order_package | vnd2 }}</span>
      </div>
      <div class="report-status">
        <span class="label">Đến kho Vn</span>
        <span class="num bg2" v-if="orderPackageClientPanel.status_10">{{ orderPackageClientPanel.status_10.total_order_package | vnd2 }}</span>
      </div>
    </div>
    <div class="box-rp">
      <div class="label-box">
        <span>Khiếu nại</span>
        <img src="@/static/icon/report/complain.svg" width="36px" height="36px" />
      </div>
      <div class="report-status" style="padding-top: 10px">
        <span class="label">Đã xử lý</span>
        <span class="num bg1" v-if="complainClientPanel.status_2">{{ complainClientPanel.status_2.total | vnd2 }}</span>
      </div>
      <div class="report-status">
        <span class="label">Đang xử lý</span>
        <span class="num bg2" v-if="complainClientPanel.status_1">{{ complainClientPanel.status_1.total | vnd2 }}</span>
      </div>
    </div>
    <!--    phần biểu đồ-->
    <div class="box-chart">
      <div class="d-flex align-items-center justify-content-center">
        <span class="type_chart" :class="typeChart === 'order' ? 'type_chart_active' : ''" @click="showChart('order')">Đơn hàng</span>
        <span class="type_chart" :class="typeChart === 'pack' ? 'type_chart_active' : ''" @click="showChart('pack')">Kiện hàng</span>
        <span class="type_chart" :class="typeChart === 'finance' ? 'type_chart_active' : ''" @click="showChart('finance')">Tài chính</span>
      </div>
      <div class="d-flex justify-content-end" style="padding: 20px 0 10px 0">
        <el-select
          v-model="timeActive"
          placeholder="Select"
          size="mini"
          style="width: 100px"
        >
          <template #prefix>
            <i class="el-icon-date" style="margin-right: 8px; margin-top: 7px"></i>
          </template>
          <el-option
            v-for="item in optionTime"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="body" style="padding: 10px 0;">
        <div v-if="typeChart === 'order'">
          <ReportOrder :checkFetchData="checkFetchData" :time="timeActive"></ReportOrder>
        </div>
        <div v-if="typeChart === 'pack'">
          <ReportPack :time="timeActive"></ReportPack>
        </div>
        <div v-if="typeChart === 'finance'">
          <ReportFinance :time="timeActive"></ReportFinance>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ReportOrder from "@/views/home/component-report/ReportOrder";
  import ReportPack from "@/views/home/component-report/ReportPack";
  import ReportFinance from "@/views/home/component-report/ReportFinance";
  import axios from "axios";
  export default {
    name: "ReportHome",
    metaInfo() {
      return {
        title: "",
        timeActive: "date",
        typeChart: "order",
      };
    },
    props: {
      drawerReport: Boolean,
    },
    components: {
      ReportOrder,
      ReportPack,
      ReportFinance,
    },
    data() {
      return {
        canSubmit: true,
        timeActive: "date",
        checkFetchData: false,
        typeChart: "order",
        orderClientPanel : {},
        orderPackageClientPanel : {},
        complainClientPanel : {},
        optionTime: [
          {
            label: "Ngày",
            value: "date",
          },
          {
            label: "Tháng",
            value: "month",
          },
          {
            label: "Năm",
            value: "year",
          },
        ],
      };
    },
    computed: {},
    mounted() {
      this.fetchOrderClientPanel();
      this.fetchOrderPackageClientPanel();
      this.fetchComplainClientPanel();
    },
    methods: {
      showChart(type) {
        this.typeChart = type;
      },
      fetchOrderClientPanel()
      {
        axios.get("report/report-order-client-panel").then((response) => {
          switch (response.code) {
            case 200:
              this.orderClientPanel = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      fetchOrderPackageClientPanel()
      {
        axios.get("report/report-order-package-client-panel").then((response) => {
          switch (response.code) {
            case 200:
              this.orderPackageClientPanel = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      fetchComplainClientPanel()
      {
        axios.get("report/report-complain-client-panel").then((response) => {
          switch (response.code) {
            case 200:
              this.complainClientPanel = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      }
    },
    watch: {
      drawerReport: function () {
        this.checkDetchData = this.drawerReport
      }
    },
  };
</script>

<style lang="css" scoped>
  .rp-home .box-rp {
    background: #ffffff;
    border-radius: 17px;
    height: 130px;
    margin: 20px 0;
    padding: 20px 30px;
  }
  .rp-home .box-rp .label-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #104D84;
    font-weight: 700;
    font-size: 14px;
  }
  .report-status {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .report-status .label {
    color: #686868;
    font-size: 14px;
    font-weight: 500;
  }
  .report-status .num {
    height: 25px;
    min-width: 65px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  .report-status .bg1{
    background: #07AF0E;
  }
  .report-status .bg2{
    background: #FFB80091;
    color: #104D84 !important;
  }
  .box-chart {
    background: #ffffff;
    border-radius: 17px;
    margin: 20px 0;
    padding: 20px 10px;
  }
  .box-chart .type_chart {
    margin: 0 8px;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EFEFEF;
    border-radius: 3px;
  }
  .type_chart_active {
    background: #CEE7FF !important;
    color: #0F56B3 !important;
  }
</style>

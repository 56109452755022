import Vue from "vue";
import Vuex from "vuex";

/**
 * -----------------------------------------------------------------------------------------------------------
 * Loading vuex modules
 * -----------------------------------------------------------------------------------------------------------
 */
import account from "@/views/account/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    path: "",
    full_path: "",
    url_back: {},
    key_mall: "sync_taobao"
  },
  getters: {
    path: (state) => state.path,
    full_path: (state) => state.full_path,
    url_back: (state) => state.url_back,
    key_mall: (state) => state.key_mall,
  },
  mutations: {
    setPath(state, path) {
      state.path = path;
    },
    setFullPath(state, full_path) {
      state.full_path = full_path;
    },
    setUrlBack(state, url_back) {
      state.url_back = url_back;
    },
    setKeyMall(state, key_mall) {
      state.key_mall = key_mall;
    },
  },
  actions: {
    setPath({ commit }, path) {
      commit("setPath", path);
    },
    setFullPath({ commit }, full_path) {
      commit("setFullPath", full_path);
    },
    setUrlBack({ commit }, url_back) {
      commit("setUrlBack", url_back);
    },
    setKeyMall({ commit }, key_mall) {
      commit("setKeyMall", key_mall);
    },
  },
  modules: {
    account,
  },
});

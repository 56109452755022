<template>
  <div class="home-index">
    <menu-dashboard></menu-dashboard>
    <news></news>
    <menu-other></menu-other>
  </div>
</template>

<script>
import MenuDashboard from "@/views/home/components/menu-dashboard"
import News from "@/views/home/components/news"
import MenuOther from "@/views/home/components/menu-other-dashboard"

export default {
  name: "DashBoard",
  metaInfo() {
    return {
      title: "Trang chủ :: Hệ thống vận chuyển",
    };
  },
  components: {
    MenuDashboard,
    News,
    MenuOther
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style lang="css" scoped>
.home-index {
  width: 100%;
}
</style>

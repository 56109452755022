<template>
  <div>
    <highcharts class="chartPack" :options="chartPack"></highcharts>
  </div>
</template>

<script>
  import {Chart} from 'highcharts-vue';
  import axios from "axios";

  export default {
    name: "ReportPack",
    metaInfo() {
      return {
        title: "",
        dataTable : []
      };
    },
    components: {
      highcharts: Chart
    },
    props: {
      time: String,
    },
    data() {
      return {
        canSubmit: true,
        chartPack: {
          chart: {
            type: 'column'
          },
          title: {
            text: '',
            align: ''
          },
          subtitle: {
            text:
              '',
            align: ''
          },
          xAxis: {
            categories: [],
            crosshair: true,
            accessibility: {
              description: 'Countries'
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            }
          },
          tooltip: {
            valueSuffix: ' '
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          series: [
            {
              name: 'Đã nhận hàng',
              data: [],
              color: '#00A962',
            },
            {
              name: 'Đến kho Việt Nam',
              data: [],
              color: '#FFB800',
            },
          ]
        },

      };
    },
    computed: {},
    mounted() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let filter = {
          type: this.time,
        };
        axios.get("report/report-order-package-client", {params : filter}).then((response) => {
          switch (response.code) {
            case 200:
              this.dataTable = response.data;
              this.chartPack.series[0].data = this.dataTable.map(item => {
                return [parseFloat(item.total_package_received_good)];
              });
              this.chartPack.series[1].data = this.dataTable.map(item => {
                return [parseFloat(item.total_package_warehouse_vn)];
              });
              this.chartPack.xAxis.categories = this.dataTable.map(item => {
                return (item.name);
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      }
    },
    watch: {
      'time':function () {
        this.fetchData();
      },
    },
  };
</script>

<style lang="css" scoped>

</style>
<style>
  .chartPack .highcharts-credits{
    display: none;
  }
</style>

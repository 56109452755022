<template>
  <div class="news">
    <el-row class="news-title d-flex justify-content-center align-items-center">
      <el-col :span="12">
        <h3 class="">Tin tức mới nhất </h3>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <el-button class="btn-more" size="mini" circle @click="toNewsPage">
          <i class="el-icon-right"></i>
        </el-button>
      </el-col>
    </el-row>

    <el-row class="mt-3">
      <el-col :span="24">
        <div class="slide" v-for="item in news" :key="item.id">
          <a :href="item.url" target="_blank">
            <div class="news-item">
              <div style="width: 30%">
                <img :src="item.image" width="100px" height="100px" />
              </div>
              <div style="width: 70%; padding: 10px 0">
                <span class="news-content">{{ item.title }}</span>
                <div class="dess">
                  <span>{{ item.dess }}</span>
                </div>
                <el-button class="btn-more mt-1" size="mini" circle @click="morePost(item)">
                  <i class="el-icon-right"></i>
                </el-button>
              </div>
            </div>
          </a>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import SsrCarousel from 'vue-ssr-carousel'
import 'vue-ssr-carousel/index.css'

export default {
  name: "DashBoardNews",
  components: {
    // SsrCarousel
  },
  data() {
    return {
      news: [
        {
            "title": "Bỏ túi kinh nghiệm nhập gạch ốp lát ...",
            "dess": "Gạch ốp lát Trung Quốc đang được mọi người yêu thích nhờ giá rẻ, mẫu mã bắt mắt, có chất lượng...",
            "image" : 'https://hangve.com/wp-content/uploads/2023/11/gach-op-lat-trung-quoc-1.jpg',
            "url": "https://hangve.com/gach-op-lat-trung-quoc/"
        },
        {
            "title": "4 kinh nghiệm nhập máy sấy tóc ...",
            "image" : 'https://hangve.com/wp-content/uploads/2023/11/may-say-toc-trung-quoc-1.jpg',
            "dess" : 'Sản phẩm máy sấy tóc Trung Quốc đang được rất nhiều người yêu thích và lựa chọn. Tuy nhiên ...',
            "url": "https://hangve.com/may-say-toc-trung-quoc/"
        },
        {
            "title": "Kinh nghiệm chọn dịch vụ vận ...",
            "image" : 'https://hangve.com/wp-content/uploads/2023/10/47-2.png',
            "dess" : 'Hiện tại, việc lựa chọn dịch vụ vận chuyển hàng Trung Quốc về Việt Nam không quá khó khăn, ...',
            "url": "https://hangve.com/kinh-nghiem-chon-dich-vu-van-chuyen-hang-trung-quoc-ve-viet-nam/"
        }
        // {
        //   id: 1,
        //   title: 'Nhập hàng chỉ với 10 triệu, cô gái kiếm...',
        //   img: ''
        // },
        // {
        //   id: 2,
        //   title: 'Nhập hàng chỉ với 10 triệu, cô gái kiếm...',
        //   img: ''
        // },
        // {
        //   id: 3,
        //   title: 'Nhập hàng chỉ với 10 triệu, cô gái kiếm...',
        //   img: ''
        // },
        // {
        //   id: 4,
        //   title: 'Nhập hàng chỉ với 10 triệu, cô gái kiếm...',
        //   img: ''
        // }
      ]
    };
  },
  mounted() {},
  methods: {
    toNewsPage() {
      window.open('https://hangve.com/tin-tuc/', '_blank');
    },
    morePost(item) {
      window.open(item.url, '_blank');
    },
  },
  watch: {},
};
</script>

<style lang="css" scoped>
  /*@import './base.scss';*/

.news {
  margin-top: 20px;
  width: 100%;
}

.news .news-title {
  width: 100%;
  height: 24px;
}

.text-title {
  font-weight: 500;
  font-size: 14px
}

.btn-more {
  background-color: #0F56B3;
  color: #ffffff;
  transform: scale(0.7)
}

.news-item {
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  /*justify-content: center;
  flex-direction: column;*/
  padding: 10px;
  margin: 10px 0;
}
.slide a {
  text-decoration: none !important;
}
.news-item img {
  border-radius: 9px;
}
.news-content {
  font-size: 14px;
  color: #737373;
  font-weight: 600;
}
.dess {
  padding-top: 10px;
  color: #989898 !important;
}

.text-content {
  font-weight: 400;
  font-size: 14px;
}
</style>

<template>
  <div class="menu-other">
    <el-row>
      <h3>Khác</h3>
    </el-row>
    <el-row class="menu-item mt-2">
      <el-col :span="6">
        <div @click="toGuide">
          <div class="bg-icon" style="background: #FFE3D7">
            <img src="@/static/icon/menu/guide.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Hướng dẫn</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toRule">
          <div class="bg-icon" style="background: #FEE4E3">
            <img src="@/static/icon/menu/rule.svg" width="36px" height="36px" style="transform: scale(1.2)" />
          </div>
        </div>
        <div class="mt-2">
          <span>Chính sách</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toNews">
          <div class="bg-icon" style="background: #E3FFF3">
            <img src="@/static/icon/menu/news.svg" width="36px" height="36px" />
          </div>
        </div>
        <div class="mt-2">
          <span>Tin tức</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div @click="toExp">
         <div class="bg-icon" style="background: #FFE3BD">
           <img src="@/static/icon/menu/exp.svg" width="36px" height="36px" />
         </div>
        </div>
        <div class="mt-2">
          <span>Kinh nghiệm</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "DashBoardMenuOther",
  components: {},
  data() {
    return {
      //
    };
  },
  mounted() {},
  methods: {
    toGuide() {
      window.open('https://hangve.com/huong-dan/', '_blank');
    },
    toRule() {
      window.open('https://hangve.com/chinh-sach/', '_blank');
    },
    toNews() {
      window.open('https://hangve.com/tin-tuc/', '_blank');
    },
    toExp() {
      window.open('https://www.thuongdo.com/cau-hoi-thuong-gap-td', '_blank');
    },
  },
  watch: {},
};
</script>

<style lang="css" scoped>
.menu-other {
  margin-top: 20px;
  background: #fff;
  padding: 10px;
  padding-bottom: 30px;
}

.menu-other .menu-item {
  width: 100%;
  height: 96px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
  .bg-icon {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border-radius: 6px;
  }
</style>

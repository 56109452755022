<template>
  <div class="account-login">
    <div class="logo">
      <img src="@/static/images/logo/hvlogo.svg" alt="" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    name: "HomeIndex",
    metaInfo() {
      return {
        title: "Home",
      };
    },
    components: {},
    data() {
      return {};
    },
    computed: {
      ...mapGetters("account", ["logged", "accountInfo"]),
    },
    mounted() {
      setTimeout(() => {
        this.$router.push({ name: "dashboard" });
      }, 1000);
    },
    methods: {
      // ...mapActions("account", ["getAccountInfo"]),
    },
    watch: {
      // logged() {
      //   if (this.logged) {
      //     this.$router.push({ name: "account-login" });
      //   }
      // },
    },
  };
</script>

<style lang="css" scoped>
  .account-login {
    overflow-y: hidden;
    overflow-x: hidden;
    min-height: 100vh;
    background: #223189;
    box-shadow: 0px 2px 4px rgba(15, 86, 179, 0.25), 0px 2px 4px rgba(15, 86, 179, 0.18);
    border-radius: 8px;
    position: relative;
  }

  .logo {
    height: 80px;
    top: 45%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .logo img {
    width: 200px;
    height: 80px;
  }

</style>

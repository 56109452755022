<template>
  <div class="header-bottom">
    <el-row :gutter="20">
      <el-col :span="5">
        <div class="item-bottom" @click="toDashboard">
          <img class="img-bottom" src="@/static/icon/common/home.svg" alt="Home Icon"/>
          <div class="text-center text-bottom">Trang chủ</div>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="item-bottom text-center" @click="toNotification" v-if="accountInfo.total_noti.all != null">
          <el-badge :value="accountInfo.total_noti.all" :max="99" class="item">
            <img class="img-bottom" src="@/static/icon/common/noti.svg" alt="Notification Icon"/>
          </el-badge>
          <div class="text-center text-bottom">Thông báo</div>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="item-bottom" @click="toOrder()">
          <img class="img-bottom" src="@/static/icon/common/order.svg" alt="Order Icon"/>
          <div class="text-center text-bottom">Đơn hàng</div>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="item-bottom text-center" @click="toCart()">
          <el-badge :value="accountInfo.total_cart" :max="99" class="item">
            <img class="img-bottom" src="@/static/icon/common/cart.svg" alt="Cart Icon"/>
          </el-badge>
          <div class="text-center text-bottom">Giỏ hàng</div>
        </div>
      </el-col>
      <el-col :span="4" class="pl-0">
        <div class="item-bottom" @click="toOther">
          <img class="img-bottom" src="@/static/icon/common/other.svg" alt="Other Icon"/>
          <div class="text-center text-bottom">Khác</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "LayoutProfileFooter",
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("account", ["accountInfo"]),
    ...mapGetters(["path", "full_path"]),
  },
  mounted() {
    this.socketNoti();
  },
  methods: {
    ...mapActions("account", ["getAccountInfo"]),
    ...mapActions("account", ["removeAccountInfo"]),
    toDashboard() {
      if (this.path === 'dashboard') {
        return;
      }

      this.$router.push({name: "dashboard"})
    },
    toNotification() {
      if (this.path === 'notification') {
        return;
      }
      this.getAccountInfo();


      this.$router.push({name: "notification"})
    },
    toOrder() {
      if (this.path === 'order-list') {
        return;
      }

      this.$router.push({name: "order-list"})
    },
    toCart() {
      if (this.path === 'cart-create') {
        return;
      }

      this.$router.push({name: "cart-create"})
    },
    toOther() {
      if (this.path === 'other') {
        return;
      }

      this.$router.push({name: "other"})
    },

    socketNoti() {
      // Join vào room
      this.$socket.emit("join-to-room-code", this.accountInfo.code);
    },
  },

  sockets: {
    connect() {
      this.socketNoti();
    },
    "global-info": function (data) {
      if (data.channel == 'noti-admin') {
        this.accountInfo.total_noti.all += 1;
      }
    },
    "complain-info": function (data) {
      if (data.channel == 'complain-info') {
        this.accountInfo.total_noti.all += 1;
      }
    },
  }
};
</script>

<style lang="css" scoped>
.header-bottom {
  height: 80px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: inset 0px 2px 8px rgba(185, 188, 190, 0.1);
  border-radius: 8px;
  z-index: 999;
}

.item-bottom {
  padding: 15px 0;
  width: 100%;
}

.img-bottom {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text-bottom {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #989898;
  margin-top: 5px;
}
</style>

<template>
  <div>
    <!-- HOTLINE, TỶ GIÁ -->
    <el-row class="hotline" style="padding: 15px 10px">
      <el-col :span="12" class="hotline1">
        <span style="color: black">Hotline: </span><span style="color: black">1900 6825</span>
      </el-col>
      <el-col :span="12" class="hotline2">
        <span  style="color: black">Tỷ giá: </span><span style="color: black">{{ configList.exchange_rate }}</span>
      </el-col>
    </el-row>

    <!-- TÀI KHOẢN, ĐĂNG XUẤT -->
    <el-row class="account-info" style="padding: 10px">
      <el-col :span="4" class="account-image">
        <img v-if="accountInfo.avatar" :src="accountInfo.avatar" class="avatar" width="40px" height="40px"/>
        <img v-else class="avatar" src="@/static/icon/header-top/account.svg" width="40px" height="40px"/>
      </el-col>
      <el-col :span="12">
        <div class="account-label"><span>Xin chào</span></div>
        <div class="account-name"><span>{{ accountInfo.name }}</span></div>
      </el-col>
      <el-col :span="8" class="text-right pt-2">
        <el-link @click="logOut">
          <img src="@/static/icon/account/logout-3.svg" class="mr-2"/>
        </el-link>
      </el-col>
    </el-row>

    <el-row class="bg-dash-v2">
      <el-col :span="24">
        <div class="d-flex align-items-center" style="font-size: 14px">
          <img src="@/static/icon/account/wallet_alt_light.svg" class="mr-2"/>
          <span>Số dư ví</span>
        </div>
      </el-col>
      <el-col :span="21" class="account-amount">
        <span v-if="checkView == false">{{ accountInfo.e_wallet | number }}đ</span>
        <span v-else>* * * * * * * * * * * * *</span>
      </el-col>
      <el-col :span="3" class="account-amount">
        <div class="d-flex justify-content-end align-items-center" @click="checkViewNumber()">
          <img v-if="checkView == false" src="@/static/icon/account/view-off.svg" style="width: 26px"/>
          <i v-else class="el-icon-view"></i>
        </div>
      </el-col>

      <el-col :span="5" class="pt-4">
        <div class="d-flex align-items-center justify-content-center">

          <div @click="drawerCategory = true">
            <img style="display: block; margin: 0 auto" src="@/static/icon/account/vector.svg"/>
            <div>
              <span>Danh mục</span>
            </div>
          </div>

          <!--<el-popover slot="append" trigger="click" width="250" popper-class="box-list">
            <div slot="reference">
              <img style="display: block; margin: 0 auto" src="@/static/icon/account/vector.svg"/>
              <div>
                <span>Danh mục</span>
              </div>
            </div>
&lt;!&ndash;            <el-button slot="reference" icon="el-icon-caret-bottom"></el-button>&ndash;&gt;
            <el-collapse class="data-list-search">
              <div v-for="item in dataListCate" :key="item.id">
                <el-collapse-item :title="item.name">
                  <div v-for="i in item.child" :key="i.key" class="txt-collapse">
                    <span v-if="i.child.length == 0" @click="showKeySearch(i)">{{ i.name }}</span>
                    <el-collapse v-else>
                      <el-collapse-item :title="i.name">
                        <div v-for="y in i.child" :key="y.id" class="txt-collapse">
                          <span @click="showKeySearch(y)">{{ y.name }}</span>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </el-collapse-item>
              </div>
            </el-collapse>
          </el-popover>-->
        </div>
      </el-col>
      <el-col :span="19" class="pt-4">
        <!-- TÌM KIẾM SẢN PHẨM -->
        <el-row class="search pl-2">
          <el-col :span="20">
            <el-input placeholder="Tìm kiếm sản phẩm" v-model="search" class="input-search">
              <!-- DANH SÁCH PLATFORM -->
              <el-select v-model="mallActive" slot="prepend" placeholder="Select" popper-class="select-mall">
                <el-option v-for="mall in malls" :label="mall.label" :value="mall.val" :key="mall.val">
                  <img v-if="mall.val == 'sync_taobao'" src="@/static/images/header-top/taobao.png" width="40px"/>
                  <img v-if="mall.val == 'sync_1688'" src="@/static/images/header-top/1688.png" width="40px"/>
                </el-option>
              </el-select>
            </el-input>
          </el-col>
          <el-col :span="3">
            <el-button class="btn-search-key" icon="el-icon-search" @click="searchProduct()"></el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>


    <!--    // phần danh mục sản phầm-->
    <div class="customm-dra-category">
      <el-drawer
        :visible.sync="drawerCategory"
        :direction="'ltr'"
        size="100%"
        :with-header="false"
        :wrapper-closable="false"
      >
        <div class="header_category">
          <el-button class="btn-more mt-1" size="mini" @click="drawerCategory = false">
            <i class="el-icon-back"></i>
          </el-button>
          <span>Danh mục sản phẩm</span>
          <span></span>
        </div>
        <div class="data-category mt-2 d-flex" style="width: 100%">
          <div class="data-left">
            <div v-for="item in dataListCate" :key="item.id">
              <div class="box-parent" :class="parentSelect == item.id ? 'active-parent' : ''" @click="selectParent(item)">
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="data-right">
            <div class="chill-1" v-if="dataChild1.length > 0">
              <div class="d-flex" style="flex-wrap: wrap">
                <span
                  @click="selectChild1(item)"
                  v-for="item in dataChild1"
                  :key="item.id"
                  class="box_child"
                  :class="childSelect == item.id ? 'active-child' : ''"
                >
                  {{ item.name }}
                </span>
              </div>
            </div>
            <div class="chill-1" v-if="dataChild2.length > 0">
              <div class="label_child py-2">
                <span>{{ nameChild1 }}</span>
              </div>
              <div class="d-flex" style="flex-wrap: wrap">
                <span
                  v-for="item in dataChild2"
                  :key="item.id"
                  class="box_child"
                  @click="selectChild2(item)"
                >
                  {{ item.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>



  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import store from "@/store";

export default {
  name: "HeaderDashboard",
  props: {},
  data() {
    return {
      // phần chọn category
      parentSelect: "",
      childSelect: "",
      drawerCategory: false,
      dataChild1: [],
      nameChild1: "",
      dataChild2: [],

      checkView: false,
      search: "",
      showSelectMall: false,
      mallActive: 'sync_taobao',
      malls: [
        {
          val: 'sync_taobao',
          label: 'Taobao',
          img: "@/static/images/header-top/taobao.png"
        },
        {
          val: 'sync_1688',
          label: '1688',
          img: "@/static/images/header-top/1688.png"
        },
      ],
      dataListCate: [],
    };
  },

  computed: {
    ...mapGetters("account", ["accountInfo", "configList"]),
    ...mapGetters(["key_mall"]),
  },

  mounted() {
    this.fetchCategory();
  },

  methods: {
    ...mapActions("account", ["removeAccountInfo"]),

    selectParent (item) {
      this.dataChild1 = item.child;
      this.nameChild1 = item.name;
      this.parentSelect = item.id;
    },
    selectChild1 (item) {
      this.dataChild2 = item.child;
      this.childSelect = item.id;
      if (item.child.length == 0) {
        this.search = item.name;
        this.drawerCategory = false;
        this.searchProduct();
      }
    },
    selectChild2 (item) {
      this.search = item.name;
      this.drawerCategory = false;
      this.searchProduct();
    },


    showKeySearch(i) {
      this.search = i.name;
      this.searchProduct();
    },
    checkViewNumber() {
      this.checkView = !this.checkView;
    },

    searchProduct() {
      console.log('searchProduct');
      if (this.search != "") {
        this.$router.push({
          name: "search-list",
          params: {
            key_search: this.search,
            request_source: 'web_mobile'
          },
        })
        this.search = "";
      }
    },

    fetchCategory() {
      axios.get("item/get-category").then((response) => {
        switch (response.code) {
          case 200:
            this.dataListCate = response.data;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },

    toBalance() {
      this.$router.push({name: 'transaction-list'})
    },

    logOut() {
      this.$confirm("Bạn muốn đăng xuất tài khoản: <br/>" + this.accountInfo.name, "", {
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy bỏ",
        type: "",
        customClass: "message-box-custom",
        center: true,
        showClose: false,
        dangerouslyUseHTMLString: true,
        cancelButtonClass: 'msgb-cancel',
        confirmButtonClass: 'msgb-confirm'
      }).then(() => {
        this.removeAccountInfo();
      }).catch(() => {
      });
    }
  },

  watch: {
    mallActive() {
      store.dispatch("setKeyMall", this.mallActive);
    },
  }
};
</script>

<style lang="css" scoped>
.btn-right {
  background: #45B4CE;
  color: #223189;
  border: none;
  transform: scale(0.7);
}

.hotline {
  padding: 10px 0;
  border-bottom: 1px solid #399CFF;
}

.hotline2 {
  text-align: right;
}

.account-info {
  margin-top: 10px;
  height: 40px;
}

.account-label {
  font-size: 13px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  color: #7C7676;
}

.account-name {
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  color: #000000;
}
.account-balance {
  margin-top: 20px;
  height: 16px;
  line-height: 16px;
}

.account-amount {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  padding: 15px 0;
}

.btn-right:hover, .btn-right:focus {
  background: #45B4CE;
  color: #223189;
}

.btn-search:hover, .btn-search:focus {
  background: #1771C1;
  color: #ffffff;
  border-radius: 0 24px 24px 0;
}

.account-image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
  .header_category {
    display: flex;
    justify-content: space-between;
    height: 56px;
    align-items: center;
    padding: 0 20px;
    background: #fff;
    color: #0F56B3;
    font-size: 16px;
    font-weight: 500;
  }
  .header_category .el-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #0F56B3;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .data-left {
    width: 40%;
    background: #EFF3F9;
    font-size: 14px;
    font-weight: 400;
    height: 100vh;
  }
  .data-right {
    width: 60%;
    margin: 0 5px;
  }
  .box-parent {
    padding: 20px 10px;
    border-radius: 10px;
  }
  .active-parent {
    background: #fff;
  }
  .active-child {
    background: #F1F7FF !important;
  }
  .chill-1 {
    background: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px 5px;
  }
  .box_child {
    background: #ECECEC;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    font-size: 14px;
    line-height: 20px;
  }
</style>

<style lang="css">
  .customm-dra-category .el-drawer__body {
    background: #F1F7FF;
    color: black;
  }
.input-search {
  background: #1771C1;
  border-radius: 24px;
}

.input-search .el-input-group__prepend {
  background-color: #1771C1;
  border: none;
  border-radius: 24px 0 0 24px;
  color: #ffffff;
  width: 15px;
  font-size: 12px;
  font-weight: 400;
  border-right: solid 1px #2196F3;
}

.input-search .el-input-group__prepend .el-input input {
  text-align: center;
  padding: unset;
}

.input-search .el-input-group__prepend .el-input .el-input__suffix {
  display: none;
}

.input-search .el-input__inner {
  background-color: #1771C1;
  border: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
}

.input-search .el-input-group__append {
  background-color: #1771C1;
  border: none;
  border-radius: 0;
  color: #ffffff;
  padding: 0 5px;
  border-right: solid 1px #2196F3;

}

.select-mall .el-scrollbar__view .el-select-dropdown__item.selected {
  background-color: #45B4CE;
}

.data-list-search {
  height: 300px;
  overflow: auto;
}

.box-list {
  padding: 12px 0 12px 12px !important;
}

.txt-collapse {
  padding: 5px 0 0 20px;
}

.btn-search-key {
  background: #1771C1 !important;
  color: #fff;
  border: none !important;
  border-radius: 0 24px 24px 0 !important;
  height: 40px;
  padding: 15px !important;
}

.btn-search-key i {
  color: #fff;
}
  .color-blue {
    color: #0F56B3;
  }
  .bg-dash-v2 {
    margin-top: 25px;
    background: linear-gradient(180deg, #183B8D 0%, #1771C1 100%);
    border-radius: 10px;
    height: 200px;
    padding: 20px 10px;
  }
</style>

<template>
  <div class="account-login">
    <el-container>
      <!-- <el-header :class="{'dashboard' : isDashboard, 'd-none' : isDetailSerarch, 'd-none' : isSerachList }" v-if="this.path != 'dashboard'" >
        <Header :isDashboard="isDashboard" :title="title" :isBack="isBack" :isLogout="isLogout" :urlBack="urlBack"></Header>
      </el-header> -->
      <el-header :class="[isDashboard ? 'dashboard' : isDetailSerarch ? 'd-none' : isSerachList ? 'd-none' : isOther ? 'd-none' : '']" v-if="this.path != 'dashboard'" >
        <Header :isDashboard="isDashboard" :title="title" :isBack="isBack" :isLogout="isLogout" :urlBack="urlBack"></Header>
      </el-header>
      <el-main
        :class="this.path == 'dashboard' ? 'el-main-dashboard' :
        this.path == 'search-list' ? 'el-main-search' :
        this.path == 'product-detail' ? 'el-main-search' :
        this.path == 'cart-create' ? 'el-main-search' :
        this.path == 'other' ? 'el-main-other' :
        this.path == 'order-contract' ? 'el-main-dashboard' :
      ''">
        <el-header :class="{'dashboard' : isDashboard }" v-if="this.path == 'dashboard'" >
          <Header :isDashboard="isDashboard" :title="title" :isBack="isBack" :isLogout="isLogout" :urlBack="urlBack"></Header>
        </el-header>
        <transition name="slide-fade">
          <div :class="this.path == 'dashboard' ? 'el-main-padding' : ''">
            <router-view />
          </div>
        </transition>
      </el-main>
      <!-- <el-footer :class="{ 'd-none' : isDetailSerarch }">
        <Footer></Footer>
      </el-footer> -->
      <el-footer :class="[isDetailSerarch ? 'd-none' : isCart ? 'd-none' : '']">
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from "vuex";
import ls from "local-storage";
import Header from "@/views/layout/Header";
import Footer from "@/views/layout/Footer";

export default {
  name: "LayoutMain",
  components: {
    Header,
    Footer
  },
  computed: {
    ...mapGetters("account", ["logged"]),
    ...mapGetters(["path", "full_path"]),
    isDashboard() {
      let routes = ['dashboard'];

      return routes.includes(this.path);
    },
    isDetailSerarch() {
      let routes = ['product-detail'];
      return routes.includes(this.path);
    },
    isSerachList() {
      let routes = ['search-list'];
      return routes.includes(this.path);
    },
    isCart() {
      let routes = ['cart-create'];
      return routes.includes(this.path);
    },
    isOther() {
      let routes = ['other'];
      return routes.includes(this.path);
    },
  },
  data() {
    return {
      title: '',
      isBack: false,
      isLogout: false,
      urlBack: ''
    };
  },
  mounted() {
    this.getTitleHeader();
  },
  updated() {
    this.getTitleHeader();
  },
  methods: {
    ...mapActions("account", ["getAccountInfo"]),
    checkLoged() {
      let authen = ls.get("authen");
      if (!authen) {
        let routesNotRedirect = ['register', 'register-success', 'forgot-password', '', 'reset-password', 'reset-success',
          'order'];
        if (!routesNotRedirect.includes(this.path)) {
          this.$router.push({ name: "account-login" });
        }
      } else {
        if (!this.logged) {
          this.getAccountInfo();
        }
      }
    },
    getTitleHeader() {
      this.$nextTick(() => {
        switch (this.path) {
          case "profile":
            this.title    = "Thông tin cá nhân";
            this.isBack   = true;
            this.isLogout = true;
            break;
          case "profile-edit":
            this.title    = "Sửa thông tin cá nhân";
            this.isBack   = true;
            this.isLogout = false;
            break;
          case "pack-list":
            this.title    = "Danh sách kiện hàng";
            this.isBack   = true;
            this.isLogout = false;
            break;
          case "order-create":
            this.title  = "Tạo đơn hàng";
            this.isBack = true;
            break;
          case "order-list":
            this.title  = "Quản lý đơn hàng";
            this.isBack = true;
            break;
          case "order-contract":
            this.title  = "Ký hợp đồng";
            this.isBack = true;
            break;
          case "order-detail":
            this.title  = "Chi tiết đơn hàng";
            this.isBack = true;
            break;
          case "order-update":
            this.title  = "Sửa đơn hàng";
            this.isBack = true;
            break;
          case "pack-to-vn":
            this.title   = "Tổng số kiện về VN";
            this.isBack  = true;
            //this.urlBack = 'pack-list';
            break;
          case "pack-detail":
            this.title   = "Chi tiết kiện hàng";
            this.isBack  = true;
            //this.urlBack = 'pack-list';
            break;
          case "delivery-list":
            this.title   = "Giao hàng";
            this.isBack  = true;
            //this.urlBack = 'pack-list';
            break;
          case "delivery-detail":
            this.title   = "Chi tiết giao hàng";
            this.isBack  = true;
            //this.urlBack = 'pack-list';
            break;
          case "consignment-create":
            this.title   = "Tạo đơn ký gửi";
            this.isBack  = true;
            break;
          case "consignment-list":
            this.title   = "Quản lý đơn ký gửi";
            this.isBack  = true;
            break;
          case "consignment-detail":
            this.title   = "Chi tiết đơn ký gửi";
            this.isBack  = true;
            break;
          case "complain-create":
            this.title   = "Tạo khiếu nại";
            this.isBack  = true;
            break;
          case "complain-success":
            this.title   = "Khiếu nại thành công";
            this.isBack  = false;
            break;
          case "complain-list":
            this.title   = "Quản lý khiếu nại";
            this.isBack  = true;
            break;
          case "complain-detail":
            this.title   = "Chi tiết khiếu nại";
            this.isBack  = true;
            break;
          case "other":
            this.title   = "Danh mục khác";
            this.isBack  = true;
            break;
          case "transaction-list":
            this.title   = "Lịch sử giao dịch";
            this.isBack  = true;
            break;
          case "transaction-withdraw":
            this.title   = "Rút tiền";
            this.isBack  = true;
            break;
          case "supplier-list":
            this.title   = "Danh sách nhà cung cấp";
            this.isBack  = true;
            break;
          case "notification":
            this.title   = "Thông báo";
            this.isBack  = true;
            break;
          case "search-list":
            this.title   = "Danh sách sản phẩm tìm kiếm";
            this.isBack  = true;
            this.urlBack = 'dashboard';
            break;
          // case "product-detail":
          //   this.title   = "Thông tin sản phẩm";
          //   this.isBack  = true;
          //   break;
          case "cart-create":
            this.title   = "Giỏ hàng";
            this.isBack  = true;
            break;
          case "dashboard":
            this.title = ""
        }
      });
    },
  },
};
</script>

<style type="text/css" scoped>

</style>

<style type="text/css">
  .account-login {
    overflow-x: hidden;
    height: 100vh;
  }

  .el-container {
    width: 100%;
    height: 100vh;
  }
  .el-header {
    padding: 0 !important;
    background: #223189;
    box-shadow: 0px 2px 4px rgba(15, 86, 179, 0.25), 0px 2px 4px rgba(15, 86, 179, 0.18);
    /*border-radius: 8px;*/
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .dashboard{
    height: 310px !important;
    padding: 0 10px !important;
    background: #F1F7FF !important;
    box-shadow: none;
  }
  .el-main {
    /*margin-bottom: 80px;*/
    padding: 20px 10px !important;
    padding-bottom: 100px !important;
  }

  .el-footer {
    padding: 0 !important;
    height: 80px !important;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }

  .message-box-custom {
    width: unset !important;
  }

  .msgb-cancel {
    background: #F4F4F4 !important;
    border-radius: 24px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .msgb-confirm {
    background: #26B01C !important;
    border-radius: 24px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .el-message-box {
    width: 80% !important;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100% !important;
  }

  .el-date-table .el-date-table__row .prev-month{
    pointer-events: none;
    opacity: 0.4;
  }

  .el-date-table .el-date-table__row .next-month {
    pointer-events: none;
    opacity: 0.4;
  }

  .el-dropdown {
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
  }

  .bd-bottom-05-ec {
    border-bottom: 0.5px solid #ECECEC;
  }

  .bd-top-05-ec {
    border-top: 0.5px solid #ECECEC;
  }

  .el-main-dashboard {
    padding: 0 0 100px 0 !important;
    background: #F1F7FF;
  }
  .el-main-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .el-main-search {
    padding: 0 !important;
  }
  .d-none {
    display: none;
  }
  .el-main-other {
    background: #F1F7FF;
    padding: 15px !important;
  }
</style>

<template>
  <div class="dashboard-content" v-if="isDashboard">
    <dashboard></dashboard>
  </div>
  <div class="breadcrumb-top-common" v-else>
    <el-row class="header-content" v-if="isBack">
      <el-col :span="3">
        <el-button class="btn-back" icon="el-icon-back" size="mini" circle @click="back"></el-button>
      </el-col>
      <el-col :span="18">
        <span class="item-breadcrumb-text">{{ title }}</span>
      </el-col>
      <el-col :span="3" class="text-right" v-if="isLogout">
        <el-popover
          placement="bottom"
          width="150"
          v-model="visible">
          <div class="header-popover">
            <div class="popover-content" @click="confirmLogout">
              <img src="@/static/icon/account/logout.svg" />
              <span class="ml-2">Đăng xuất</span>
          </div>
          </div>
          <el-button slot="reference" class="btn-more"><i class="el-icon-more-outline"></i></el-button>
        </el-popover>
      </el-col>
    </el-row>

    <el-row class="header-content" v-else>
      <el-col :span="24">
        <span class="item-breadcrumb-text">{{ title }}</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Dashboard from "@/views/layout/HeaderDashboard"

export default {
  name: "LayoutProfileHeader",
  props: {
    isDashboard: Boolean,
    title: String,
    isBack: Boolean,
    isLogout: Boolean,
    urlBack: String
  },
  components: {
    Dashboard
  },
  data() {
    return {
      visible: false,
      search: "",
      showSelectMall: false,
      mallActive: 1,
    };
  },
  computed: {
    ...mapGetters("account", ["accountInfo", "configList"]),
    // ...mapGetters(["path", "full_path"]),
  },
  created() {
  },
  mounted() {
    //console.log(this.path, this.account, this.accountInfo, 111);
  },
  methods: {
    ...mapActions("account", ["removeAccountInfo"]),

    confirmLogout() {
      this.$confirm("Bạn muốn đăng xuất tài khoản: <br/>" + this.accountInfo.name, "", {
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy bỏ",
        type: "",
        customClass: "message-box-custom",
        center: true,
        showClose: false,
        dangerouslyUseHTMLString: true,
        cancelButtonClass: 'msgb-cancel',
        confirmButtonClass: 'msgb-confirm'
      })
      .then(() => {
        this.removeAccountInfo();
      })
      .catch(() => {});
    },

    back() {
      if (this.urlBack) {
        this.$router.push({ name: this.urlBack })
          .then()
          .catch(() => { this.$router.go() });
      } else {
        this.$router.back();
      }
    }
  },
};
</script>

<style lang="css" scoped>
  .dashboard-content {
    width: 100%;
    color: #ffffff;
  }

  .breadcrumb-top-common {
    width: 100%;
  }
  .item-breadcrumb-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .header-content {
    /*width: 100%;*/
    margin: 16px 24px;
  }

  .btn-back {
    background: #17205B;
    color: #ffffff;
    border: none;
  }

  .btn-more {
    background: #223189;
    color: #ffffff;
    border: none;
    height: 24px;
    transform: rotate(90deg);
  }

  .el-button:focus, .el-button:hover {
    background: #223189;
    color: #ffffff;
    border: none;
  }

  .header-popover {
    margin: 0;
    height: 40px !important;
  }

  .header-popover .popover-content {
    padding: 14px 20px;
  }

</style>

<style lang="css">

</style>

export function number(number, decimal) {
  decimal = typeof decimal === "undefined" ? 0 : decimal;
  return formatNumber(number, decimal);
}

export function vnd(money, decimal) {
  decimal = typeof decimal === "undefined" ? 0 : decimal;
  return formatNumber(money, decimal) + " đ";
}

export function vnd2(money, decimal) {
  decimal = typeof decimal === "undefined" ? 0 : decimal;
  return formatNumber(money, decimal);
}

export function vnd3(money, decimal) {
  decimal = typeof decimal === "undefined" ? 0 : decimal;
  return formatNumber(money, decimal) + " ¥";
}

/**
 * Format dữ liệu số sử dụng ký tự 'k', lấy 1 số sau dấu phẩy
 * Ví dụ: 5000 -> 5k, 5600 -> 5,6k, 12100 -> 12,1k
 *
 * @param money
 * @param decimal
 */
export function vnd4(number) {
  const formattedNumber = Number(number);

  const rs = (formattedNumber/1000) >= 1
    ? formattedNumber % 1000 ? `${(formattedNumber/1000).toFixed(1)}k` : `${(formattedNumber/1000).toFixed(0)}k`
    : formattedNumber;

  return String(rs).replace('.', ',');
}

export function gam(gam, decimal) {
  decimal = typeof decimal === "undefined" ? 0 : decimal;
  return formatNumber(gam, decimal) + " g";
}

export function cm(cm, decimal) {
  decimal = typeof decimal === "undefined" ? 0 : decimal;
  return formatNumber(cm, decimal) + " cm";
}

export function percent(percent, decimal) {
  decimal = typeof decimal === "undefined" ? 0 : decimal;
  return formatNumber(percent, decimal) + " %";
}

export function currency(value, decimal) {
  if (value) {
    let number = +value.replace(/[^\d.]/g, "");
    number = isNaN(number) ? 0 : number;
    decimal = typeof decimal === "undefined" ? 0 : decimal;
    return formatNumber(number, decimal);
  }
}

function formatNumber(number, places, thousand, decimal) {
  number = number || 0;
  places = !isNaN((places = Math.abs(places))) ? places : 2;
  thousand = thousand || ",";
  decimal = decimal || ".";
  let negative = number < 0 ? "-" : "";
  let i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + "";
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  return (
    negative +
    (j ? i.substr(0, j) + thousand : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) +
    (places
      ? decimal +
        Math.abs(number - i)
          .toFixed(places)
          .slice(2)
      : "")
  );
}

export function convertDate(date, prefix = '-') {
  let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join(prefix);
}

export function roundMoney(number) {
  number = Math.round(number);
  return number;

  /*let subNumber = String(number).substr(-3, 3);
  subNumber     = parseInt(subNumber);

  let result = 0;
  if (subNumber === 0) {
    result = number;
  } else {
    if (subNumber <= 500) {
      result = number + (500 - subNumber);
    } else {
      result = number + (1000 - subNumber);
    }
  }

  if (!type) {
    return result;
  }

  if (type === 'vnd') { // vnđ
    return vnd(result);
  } else { // ¥
    return vnd3(result);
  }*/
}
